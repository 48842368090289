


































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";

@Component
export default class component_name extends Vue {
  message = "";
  color = "";
  show = false;
  meta = {};
  @Watch("$store.state.showError")
  showMessage() {
    this.message = this.$store.state.showError.message;
    this.color = this.$store.state.showError.color;
    this.show = true;
    this.meta = this.$store.state.showError.meta || {};
  }
}
