import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import { Component } from "vue-property-decorator";
import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: { id: "G-WGZ2K91T8P" },
    appName: "GO2TR BMS",
    pageTrackerScreenviewEnabled: true
  },
  router
);
import moment from "moment-jalaali";
moment.locale("fa");
moment.loadPersian();
Vue.prototype.moment = moment;
import "@/plugins/filters";
import Vuelidate from "vuelidate";
Component.registerHooks(["validations"]);
Vue.use(Vuelidate);

import LaravelPermissions from "laravel-permissions";
Vue.use(LaravelPermissions);

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
