import Vue from "vue";
import Vuetify from "vuetify/lib";
import fa from "vuetify/src/locale/fa";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@/assets/css/style.css";
Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#E6AF2E",
        secondary: "#ef6c00",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#e6af2e",
        success: "#4CAF50",
        warning: "#FFC107",
        text: '#000'
      },
      dark: {
        primary: "#E6AF2E",
        secondary: "#ef6c00",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#e6af2e",
        success: "#4CAF50",
        warning: "#FFC107",
        text: '#fff'
      }
    }
  },
  lang: {
    locales: { fa },
    current: "fa"
  }
});
