import Vue from "vue";
import moment from "moment-jalaali";

Vue.filter("capitalize", function (value: string) {
  if (!value) return "";
  value = value.toString();
  const arr = value.split(" ");
  const capitalizedArray: Array<string> = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(" ");
});

Vue.filter("toCurrency", function (value: number) {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("fa-IR", {
    minimumFractionDigits: 0
  });
  return formatter.format(value) + " تومان";
});

Vue.filter("to098", function (value: string) {
  return value.replace("+98", "0");
});

Vue.filter("toDate", function (value: string): string {
  return moment(value).format("jYYYY jMMMM jDD");
});

Vue.filter("toDateTime", function (value: string): string {
  return moment(value).format("HH:mm jYYYY/jMM/jDD");
});

Vue.filter("friendly_duration", function (value: number): string {
  return moment.utc(value * 1000).format("HH:mm:ss");
});

Vue.filter("full_avatar", function (value: string | null): string {
  if (process.env.NODE_ENV == "development")
    return value ? `${value}` : require("@/assets/avatar.png");
  return value
    ? `${value}`
    : require("@/assets/avatar.png");
});
