import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: process.env.CORDOVA_PLATFORM ? "hash" : "history",
  base: "/",
  routes: [
    {
      path: "/",
      redirect: "/dashboard"
    },
    {
      path: "/survey/:srvId/:evntId/:usreId",
      name: "Survey",
      meta: {
        title: "نظرسنجی",
        requireAuth: false
      },
      component: () => import("./views/pages/survey/index.vue")
    },
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./views/layouts/main/Main.vue"),
      meta: {
        requireAuth: true
      },
      name: "Home",
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/qr/:id?",
          redirect: "/quick-register/:id?"
        },
        {
          path: "/dashboard",
          name: "dashboard",
          meta: {
            title: "داشبورد",
            requireAuth: true
          },
          component: () => import("./views/pages/Home/Dashboard.vue")
        },
        {
          path: "/quick",
          name: "quick",
          meta: {
            title: "ثبت سریع مشتری",
            requireAuth: true
          },
          component: () => import("./views/pages/User/quick.vue")
        },
        {
          path: "/calls",
          name: "calls",
          meta: {
            title: "تماس ها",
            requireAuth: true
          },
          component: () => import("./views/pages/Call/index.vue")
        },
        {
          path: "/users-add",
          name: "users-add",
          meta: {
            title: "کلاینت جدید",
            requireAuth: true
          },
          component: () => import("./views/pages/User/AddUser.vue")
        },
        {
          path: `/users/:id`,
          name: "users-edit",
          meta: {
            title: "ویرایش کاربر",
            requireAuth: true
          },
          component: () => import("./views/pages/User/AddUser.vue")
        },
        {
          path: `/users/:id/resume`,
          name: "users-resume",
          meta: {
            title: "رزومه کاربر",
            requireAuth: true
          },
          component: () => import("./views/pages/User/resume.vue")
        },
        {
          path: "/users",
          name: "users",
          meta: {
            title: "کاربران",
            requireAuth: true
          },
          component: () => import("./views/pages/User/ListUser.vue")
        },
        {
          path: "/financial",
          name: "finance",
          meta: {
            title: "امور مالی",
            requireAuth: true
          },
          component: () => import("./views/pages/Finance/index.vue")
        },
        {
          path: "/advisers",
          name: "advisers",
          meta: {
            title: "کاربران",
            requireAuth: true
          },
          component: () => import("./views/pages/User/Advisers.vue")
        },
        // {
        //   path: "/events-add",
        //   name: "events-add",
        //   meta: {
        //     title: "افزودن رویداد",
        //     requireAuth: true
        //   },
        //   component: () => import("./views/pages/events/AddEvent.vue")
        // },
        {
          path: "/events",
          name: "events",
          meta: {
            title: "رویدادها",
            requireAuth: true
          },
          component: () => import("./views/pages/events/ListEvents.vue")
        },
        {
          path: "/events/user-add",
          name: "events-user-add",
          meta: {
            title: "رویدادها",
            requireAuth: true
          },
          component: () => import("./views/pages/events/UserEvent.vue")
        },
        {
          path: "/events/:id",
          name: "events-view",
          meta: {
            title: "مشاهده رویداد",
            requireAuth: true
          },
          component: () => import("./views/pages/events/view.vue")
        },
        {
          path: "/events/edit/:id",
          name: "events-edit",
          meta: {
            title: "ویرایش رویداد",
            requireAuth: true
          },
          component: () => import("./views/pages/events/AddEvent.vue")
        },
        {
          path: "/events/edit/:id/admin",
          name: "events-admin-set-mentors",
          meta: {
            title: "ست کردن مشاوران",
            requireAuth: true
          },
          component: () => import("./views/pages/events/SetMentorsByAdmin.vue")
        },
        {
          path: "/events/edit/:id/contract",
          name: "contract",
          meta: {
            title: "قرارداد",
            requireAuth: true
          },
          component: () => import("./views/pages/events/Contract.vue")
        },
        {
          path: "/report/general",
          name: "report",
          meta: {
            title: "گزارشات کلی",
            requireAuth: true
          },
          component: () => import("./views/pages/Report/general.vue")
        },
        {
          path: "/report/call",
          name: "report-call",
          meta: {
            title: "گزارشات تماس ها",
            requireAuth: true
          },
          component: () => import("./views/pages/Report/call.vue")
        },
        {
          path: "/report/bests",
          name: "report-bests",
          meta: {
            title: "بهترین ها",
            requireAuth: true
          },
          component: () => import("./views/pages/Report/best.vue")
        },
        {
          path: "/report/week",
          name: "report-week",
          meta: {
            title: "گزارشات هفتگی",
            requireAuth: true
          },
          component: () => import("./views/pages/Report/week.vue")
        },
        {
          path: "/offices",
          name: "offices",
          meta: {
            title: "دفاتر",
            requireAuth: true
          },
          component: () => import("./views/pages/Setting/office/Offices.vue")
        },
        {
          path: "/offices/edit/:id",
          name: "offices-edit",
          meta: {
            title: "ویرایش دفتر",
            requireAuth: true
          },
          component: () => import("./views/pages/Setting/office/EditOffice.vue")
        },
        {
          path: "/offices-add",
          name: "offices-add",
          meta: {
            title: "افزودن دفتر",
            requireAuth: true
          },
          component: () => import("./views/pages/Setting/office/EditOffice.vue")
        },
        {
          path: "/access",
          name: "access",
          meta: {
            title: "دسترسی ها",
            requireAuth: true
          },
          component: () =>
            import("./views/pages/Setting/Permissions/Permissions.vue")
        },
        // {
        //   path: "/access/role/create",
        //   name: "access-role-create",
        //   meta: {
        //     title: "افزودن نقش",
        //     requireAuth: true
        //   },
        //   component: () =>
        //     import("./views/pages/Setting/Permissions/AddRole.vue")
        // },
        {
          path: "/access/role/users/:id",
          name: "access-role",
          meta: {
            title: "کاربران نقش",
            requireAuth: true
          },
          props: true,
          component: () => import("./views/pages/Setting/Permissions/Users.vue")
        },
        {
          path: "/access/role/:id",
          name: "access-role-edit",
          meta: {
            title: "ویرایش نقش",
            requireAuth: true
          },
          props: true,
          component: () =>
            import("./views/pages/Setting/Permissions/AddRole.vue")
        },
        {
          path: "/access/permissions/:id",
          name: "access-permission",
          meta: {
            title: "کاربران دسترسی",
            requireAuth: true
          },
          props: true,
          component: () =>
            import("./views/pages/Setting/Permissions/PermissionUsers.vue")
        },
        {
          path: "/notifications",
          name: "notifications",
          meta: {
            title: "مدیریت پیام ها",
            requireAuth: true
          },
          props: true,
          component: () =>
            import("./views/pages/Setting/notifications/list.vue")
        },
        {
          path: "/notifications/:id",
          name: "notifications-edit",
          meta: {
            title: "ویرایش پیام",
            requireAuth: true
          },
          props: true,
          component: () =>
            import("./views/pages/Setting/notifications/upsert.vue")
        },
        {
          path: "/profile",
          name: "profile",
          meta: {
            title: "پروفایل",
            requireAuth: true
          },
          component: () => import("./views/pages/Profile/Profile.vue")
        },
        {
          path: "/profile-file",
          name: "file",
          meta: {
            title: "پرونده",
            requireAuth: true
          },
          component: () => import("./views/pages/Profile/File/File.vue")
        },
        {
          path: "/profile-file-detail",
          name: "file-detail",
          meta: {
            title: "جزئیات پرونده",
            requireAuth: true
          },
          component: () => import("./views/pages/Profile/File/FileDetail.vue")
        },
        {
          path: "/contracts",
          name: "contract-list",
          meta: {
            title: "قرارداد ها",
            requireAuth: true
          },
          component: () => import("./views/pages/Contract/contract-list.vue")
        },
        {
          path: "/contracts/:id",
          name: "contract-item",
          meta: {
            title: "قرارداد",
            requireAuth: true
          },
          component: () => import("./views/pages/Contract/contract-view.vue")
        },
        {
          path: "/contracts/:id/edit",
          name: "contract-edit",
          meta: {
            title: "تغییر قرارداد",
            requireAuth: true
          },
          component: () => import("./views/pages/Contract/contract-edit.vue")
        },
        {
          path: "/contracts/:id/transaction",
          name: "contract-transaction",
          meta: {
            title: "پرداخت قرارداد",
            requireAuth: true
          },
          component: () =>
            import("./views/pages/Contract/contract-transaciton.vue")
        },
        {
          path: "/coupon/list",
          name: "coupon-list",
          meta: {
            title: "لیست کوپن ها",
            requireAuth: true
          },
          component: () => import("./views/pages/Coupon/list.vue")
        },
        {
          path: "/coupon/create",
          name: "coupon-create",
          meta: {
            title: "ساخت کوپن",
            requireAuth: true
          },
          component: () => import("./views/pages/Coupon/create.vue")
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "/login",
      name: "login",
      meta: {
        requireAuth: false
      },
      component: () => import("@/views/pages/Auth/Login.vue")
    },
    {
      path: "/pay/:event/:check",
      name: "pay",
      meta: {
        title: "پرداخت هزینه مشاوره",
        requireAuth: false
      },
      component: () => import("@/views/pages/Auth/pay.vue")
    },
    {
      path: "/p/:transaction/:user",
      name: "pay-transaction",
      meta: {
        title: "پرداخت فاکتور",
        requireAuth: false
      },
      component: () => import("@/views/pages/Finance/pay.vue")
    },
    {
      path: "/quick-register/profile/:uid",
      name: "completeProfile",
      meta: {
        title: "تکمیل رزومه برای مشاوره",
        requireAuth: false
      },
      component: () => import("@/views/pages/Profile/PublicInfo.vue")
    },
    {
      path: "/quick-register/:id?",
      name: "quickRegisterPublic",
      meta: {
        title: "ثبت مشاوره GO2TR",
        requireAuth: false
      },
      component: () => import("@/views/pages/events/UserEventPublic.vue")
    },
    {
      path: "/country-contract/:country?",
      name: "countryContract",
      meta: {
        title: "قرارداد کشور | GO2TR",
        requireAuth: false
      },
      component: () => import("@/views/pages/Contract/country-contract.vue")
    },
    {
      path: "/confirm-contract/:uid",
      name: "confirmContract",
      meta: {
        title: "تایید قرارداد کشور | GO2TR",
        requireAuth: false
      },
      component: () => import("@/views/pages/Contract/confirm-contract.vue")
    },
    {
      path: "/confirm-payment/:id",
      name: "confirmPayment",
      meta: {
        title: "تایید پرداخت و زمان مشاوره",
        requireAuth: false
      },
      component: () => import("@/views/pages/events/confirmPayment.vue")
    },
    {
      path: "/error-404",
      name: "404",
      meta: {
        requireAuth: false
      },
      component: () => import("@/views/pages/Auth/404.vue")
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/error-404"
    }
  ]
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (token == null) {
      next({
        name: "login",
        params: { nextUrl: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
