export default {
  showError(state: { showError: any }, paylod: any) {
    state.showError = paylod;
  },
  saveUser(state: { user: any }, paylod: any) {
    state.user = paylod;
  },
  openReminder(state: { reminderDialog: boolean }, paylod: boolean) {
    state.reminderDialog = paylod;
  }
};
