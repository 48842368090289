export default {
  showError: {
    message: "",
    color: ""
  },
  user: {
    firstname: "",
    lastname: "",
    avatar: ""
  },
  reminderDialog: false
};
